import printJS from 'print-js'
import base from "@/common/base.js";
export default {
  changeSignatureHandle(){
    this.$EventBus.$emit("signatureChange",this.isSignaturePrint)
  },
  showUpdate(){
    this.isShowAlonePrint = false
  },
  closePage(){
    if (navigator.userAgent.indexOf('MSIE') > 0) { // close IE
      if (navigator.userAgent.indexOf('MSIE 6.0') > 0) {
         window.opener = null;
         window.close();
      } else {
         window.open('', '_top');
         window.top.close();
      }
    } else { // close chrome;It is effective when it is only one.
      window.opener = null;
      window.open('', '_self');
      window.close();
    }
  },
  aloneprintPage(){
    var pageDiv=document.getElementsByClassName("page")[0];
    if(this.params.confidential=='false'||this.params.confidential==false){  
      pageDiv.style.backgroundImage="";
    }else{  
      pageDiv.style.backgroundImage="url("+this.imageUrl+")"; 
    }
    if(this.params.majorConstituentsInNativeHerbs){
      this.strSplit(this.params.majorConstituentsInNativeHerbs.replace(/,/g,"_"), "majorConstituentsInNativeHerbsArr");
    }
    const style = '@page {size:  auto; margin:3mm} @media print{} '
    var printFileName = this.params.productName+"Rev."+this.noteTableData.length;
    document.title = "(spec)"+printFileName;
    setTimeout(() =>{
        printJS({
          printable:'alonePrintDiv',
          type:'html',
          style:style,
          scanStyles:false
        })
    },1.5);

  },
  printPage(){
    console.log(this.params.confidential);
    var pageDiv=document.getElementsByClassName("page")[0];
    if(this.params.confidential=='false'||this.params.confidential==false){  
      pageDiv.style.backgroundImage="";
    }else{  
      pageDiv.style.backgroundImage="url("+this.imageUrl+")"; 
    }
    if(this.params.majorConstituentsInNativeHerbs){
      this.strSplit(this.params.majorConstituentsInNativeHerbs.replace(/,/g,"_"), "majorConstituentsInNativeHerbsArr");
    }
    this.printShow = true;
    //margin:10mm 0mm 10mm 0mm; 
    //size: auto; margin:0mm;
    const style = '@page {size:  auto; margin:3mm} @media print{} '
    var printFileName = this.params.productName+"_"+this.params.itemCode+"_Rev."+this.params.revNumber;//.replace(':','~').replace('：','~');
    document.title = "(spec)"+printFileName;
    //console.log(document.title);
    setTimeout(() =>{
        printJS({
          printable:'printDiv',
          type:'html',
          style:style,
          scanStyles:false
        })
    },1.5);
    
    setTimeout(() =>{
        this.printShow = false;   
    },3);

  },
  printCnLPage(){
    var pageDiv=document.getElementsByClassName("page")[0];
    if(this.form.confidential=='false'||this.form.confidential==false){  
      pageDiv.style.backgroundImage="";
    }else{  
      pageDiv.style.backgroundImage="url("+this.imageUrl+")"; 
    }
    //console.log(pageDiv.style.backgroundImage);
    //this.strSplit(this.form.majorConstituentsInNativeHerbs.replace(/,/g,"_"), "majorConstituentsInNativeHerbsArr");
    
    this.printShow = true;
    //margin:10mm 0mm 10mm 0mm; 
    //size: auto; margin:0mm;
    const style = '@page {size:  auto; margin:3mm} @media print{} '
    var printFileName = this.form.BaseInfoProductName+"_"+this.form.BaseInfoItemCode+"_Rev."+this.form.revNumber;
    document.title = "(spec)"+printFileName;
    //console.log(document.title);
    setTimeout(() =>{
        printJS({
          printable:'printDiv',
          type:'html',
          style:style,
          scanStyles:false
        })
    },1.5);
    
    setTimeout(() =>{
        this.printShow = false;   
    },3);

  },
  printCnEPage(){
    var pageDiv=document.getElementsByClassName("page")[0];
    if(this.form.confidential=='false'||this.form.confidential==false){  
      pageDiv.style.backgroundImage="";
    }else{  
      pageDiv.style.backgroundImage="url("+this.imageUrl+")"; 
    }
    //console.log(pageDiv.style.backgroundImage);
    //this.strSplit(this.form.majorConstituentsInNativeHerbs.replace(/,/g,"_"), "majorConstituentsInNativeHerbsArr");
    
    this.printShow = true;
    //margin:10mm 0mm 10mm 0mm; 
    //size: auto; margin:0mm;
    const style = '@page {size:  auto; margin:3mm} @media print{} '
    var printFileName = this.form.productName+"_"+this.form.itemCode+"_Rev."+this.form.revNumber;
    document.title = "(spec)"+printFileName;
    //console.log(document.title);
    setTimeout(() =>{
        printJS({
          printable:'printDiv',
          type:'html',
          style:style,
          scanStyles:false
        })
    },1.5);
    
    setTimeout(() =>{
        this.printShow = false;   
    },3);

  },
  printCnSPage(){
    var pageDiv=document.getElementsByClassName("page")[0];
    if(this.form.confidential=='false'||this.form.confidential==false){  
      pageDiv.style.backgroundImage="";
    }else{  
      pageDiv.style.backgroundImage="url("+this.imageUrl+")"; 
    }
    //console.log(pageDiv.style.backgroundImage);
    //this.strSplit(this.form.majorConstituentsInNativeHerbs.replace(/,/g,"_"), "majorConstituentsInNativeHerbsArr");
    
    this.printShow = true;
    //margin:10mm 0mm 10mm 0mm; 
    //size: auto; margin:0mm;
    const style = '@page {size:  auto; margin:3mm} @media print{} '
    var printFileName = this.form.BaseInfoProductName+"_"+this.form.BaseInfoItemCode+"_Rev."+this.form.revNumber;
    document.title = "(spec)"+printFileName;
    //console.log(document.title);
    setTimeout(() =>{
        printJS({
          printable:'printDiv',
          type:'html',
          style:style,
          scanStyles:false
        })
    },1.5);
    
    setTimeout(() =>{
        this.printShow = false;   
    },3);

  },
  addfattyAcidComposition() {
    this.fattyAcidComposition.fattyCustomFieldAttr = []
    this.fattyAcidCompositionArr.push({ ...this.fattyAcidComposition });
  },
  addSHTest() {
    this.shTest.testCustomFieldAttr = []
    this.shTestArr.push({ ...this.shTest });
  },
  // productType ehxto init
  initEHXTO() {
    this.colorD = false;
    this.params.testLimitMoisture = 1;
    this.params.countryOfOrigin = "China";
    this.params.color = "Light brown";
    this.params.appearance = "Light brown clear oil";
    this.params.odor = "Characteristic";
    this.params.taste = "Characteristic";
    this.params.testLimitSpecificaGravity = "1.0200~1.0430 g/mL";
    this.params.testLimitRefractiveIndex = "1.5750~1.6010";
    this.params.testLimitOpticalRotation = "-3°~+1°";
    this.params.testLimitHeavyMetals = "10";
    this.params.specificationTotalColiforms = "10";
    this.params.testLimitArsenic = "<0.5";
    this.params.testLimitCadmium = "<0.5";
    this.params.testLimitLead = "<0.5";
    this.params.testLimitMercury = "<0.5";
    this.params.testMethodMercury = "USP<232>";
    this.params.testMethodLead = "USP<232>";
    this.params.testMethodCadmium = "USP<232>";
    this.params.testMethodArsenic = "USP<232>";
    this.params.testMethodHeavyMetals = "USP <231>";
    this.params.testMethodOpticalRotation = "USP<781>";
    this.params.testMethodRefractiveIndex = "USP<831>";
    this.params.testMethodSpecificaGravity = "USP<841>";
    this.params.testMethodMeshSize = "USP<921>";
  },
  // productType shxto init
  initSHXTO() {
    this.colorD = false;
    this.params.color = "Red brown";
    this.params.appearance = "Viscous liquid";
    this.params.odor = "Characteristic";
    this.params.taste = "Characteristic";

    this.params.testLimitAcidValue = "";//12.0
    this.params.testLimitPeroxideValue = "";//10.0
    this.params.testLimitSaponificationValue = "";//130.0-200.0
    this.params.testLimitUnsaponifiableMaster = "";//1.0-3.0
    this.params.testLimitMoistureVolatiles = "";//0.50
    this.params.testLimitLead = "";//1.0
    this.params.testLimitArsenic = "";//1.0
    this.params.testLimitCadmium = "";//1.0
    this.params.testLimitMercury = "";//1.0
    this.params.testLimitPesticides = "Conforms to USP <561>";
    this.params.testMethodAcidValue = "AOCS Cd 3d-63";
    this.params.testMethodPeroxideValue = "AOCS Cd 8b-90";
    this.params.testMethodSaponificationValue = "AOCS Cd 3-25";
    this.params.testMethodUnsaponifiableMaster = "AOCS Ca 6a-40";
    this.params.testMethodMoistureVolatiles = "AOCS Ca 2d-25";
    this.params.testMethodLead = "AOCS Ca 18c-91";
    this.params.testMethodArsenic = "AOAC 986.15";
    this.params.testMethodCadmium = "AOAC 986.15";
    this.params.testMethodMercury = "ACAC 971.21";
    this.params.testMethodPesticides = "USP <561>";

    // this.params.fattryLimitPalmitica = "28.0-35.0";
    // this.params.fattryLimitPalmitoleic = "25.0-55.0";
    // this.params.fattryLimitStearic = "0-3.0";
    // this.params.fattryLimitOleicVaccenic = "21.0-32.0";
    // this.params.fattryLimitLinoleic = "3.0-12.0";
    // this.params.fattryLimitALinolenic = "0-4.0";
    // this.params.fatterLimitOthers = "0-3.0";
    // this.params.fattryMethodPalmitica = "AOCS Ce 1e-91";
    // this.params.fattryMethodPalmitoleic = "AOCS Ce 1e-91";
    // this.params.fattryMethodStearic = "AOCS Ce 1e-91";
    // this.params.fattryMethodOleicVaccenic = "AOCS Ce 1e-91";
    // this.params.fattryMethodLinoleic = "AOCS Ce 1e-91";
    // this.params.fattryMethodALinolenic = "AOCS Ce 1e-91";
    // this.params.fatterMethodOthers = "AOCS Ce 1e-91";
    this.fattyAcidCompositionArr = [{ ...this.fattyAcidComposition }];
    this.shTestArr = [{...this.shTest}];
    this.params.packageInfo =
      "packaged in 20 or 190 kg net drum, purged with nitrogen.";
      // "Organic Sea Buckthorn Oil is packaged in 20 or 190 kg net drum, purged with nitrogen.";

    
    this.params.specificationTotalPlateCount = "";
    this.params.specificationYeastAndMold = "";
    this.params.specificationEcoli = "";
    this.params.specificationSalmonella = "";
    this.params.specificationStaphAureus = "";
    this.params.specificationTotalColiforms = "";
  },
  // productType liquidExt init  liquid EXT-S，-B，SDNP-L
  initLiquidEXT() {
    this.colorD = true;
    this.params.color = "Compares to standard";
    this.params.odor = "Compares to standard";
    this.params.taste = "Compares to standard";
    this.params.productDefinition = "Liquid botanical extract solution prepared by mixing full spectrum bioactive extract with solvent. Original extract is derived from a proprietary, closed system, temperature controlled process in which the majority of the active phytocompounds are retained for full bioactivity.";
    this.params.botanicalExtractionProcess = "Controlled temperature full-spectrum extraction process";
    this.params.botanicalSolventForExtraction = "Water";
    this.params.analysisRangeBioactivesLevel = "";
    this.params.analysisRangePh = "3.5-7.5";
    this.params.analysisRangeHeavyMetal = "Conforms to USP <231>";
    this.params.analysisRangePesticide = "Conforms to USP<565>";
    this.params.analysisRangeTotalPlateCount = "100";
    if(this.params.analysisRangeYeastMold==""){
      this.params.analysisRangeYeastMold = "100";
    } 
    this.params.analysisMethodPh = "pH meter";
    this.params.analysisMethodHeavyMetal = "USP <231>";
    this.params.analysisMethodPesticide = "USP<565>";
    this.params.analysisMethodTotalPlateCount = "FDA BAM";
    this.params.analysisMethodYeastMold = "FDA BAM";
    this.params.shelflife = "2 years unopened";
    this.params.shelflifeNumber = "24"

    
    this.params.testMethodTotalColiforms = "";
  },
  // 模块显示隐藏控制
  moduleVisibleCon(type) { 
    switch (type) {
      case "SHXTO":
        // this.productTypeVisible = {
        //   shxto: true
        // };
        this.productTypeVisible = {
          ehxto: true,
          microbiologicalShow:true
        };
        break;
      case "Liquid EXT-S":
      case "Liquid EXT-B":
      case "SDNP-L":
        this.productTypeVisible = {
          liquidExt: true,
        };
        break;
      case "EHXTO":
        this.productTypeVisible = {
          shxto: true,
          microbiologicalShow:true
        };
        break;
      default:
        this.productTypeVisible = {
          normal: true,
          shxto: false,
          liquidExt: false,
          ehxto: false,
        };
        break;
    } 
  },
  // ingredients标签操作
  handleClose(tag, arr,type="") {
    this[arr].splice(this[arr].indexOf(tag), 1);
    if(arr=="botanicalNameArr"){
      if(type =="cn-power"){
        this.$parent.params.BaseInfoProductDescription="经有机认证的全谱工艺提取、喷雾干燥的粉末状 "+this.botanicalNameArr.toString()+"植物提取物。"
      }
      if (
        type == "CSFMU-B" || type == "CSFMU-S" || type == "REXT" || 
        type == "F&V" || type == "FMU-D" || type == "HEXT" || 
        type == "OEXT" || type == "PCG" || type == "PCG-CSFMU-S" || 
        type == "SDNP" || type == "PCG-CSFMU-B"
      ) {
        this.params.productDescription="Spray dried, fine powdered botanical extract of "+this.botanicalNameArr.toString()+" that has been extracted in a medium temperature, full spectrum extraction process. "
      }
    }
  },
  showInput(inputVisible, ref) {
    this[inputVisible] = true;
    this.$nextTick(() => {
      this.$refs[ref].$refs.input.focus();
    });
  },
  showIndexInput(inputVisible, ref,index,arrIndex) {
    this[arrIndex] = index+1;
    this[inputVisible] = true;
    this.$nextTick(() => {
      this.$refs[ref].$refs.input.focus();
    });
  },
  handleInputConfirm(value, arr, visible,type="") {
    console.log("value====arr====visible")
    console.log(value)
    console.log(arr)
    console.log(visible)
    let inputValue = this[value];
    if (inputValue) {
      if(arr=="commonNameArr" || arr=="inclNameArr" || arr=="botanicalNameArr"){
        this.getAllName(inputValue,arr)
      }
      console.info(this[arr]);
      console.log(this[arr+"Index"])
      if(this[arr+"Index"] != undefined && this[arr+"Index"] != null && this[arr+"Index"] != "" && parseInt(this[arr+"Index"])>0){
        this[arr].splice(this[arr+"Index"],0,inputValue);
        console.log(this[arr+"Index"]+"=========")
        this[arr+"Index"] = -1
      }
      else{
        this[arr].push(inputValue);
      }
    }
    if(arr=="botanicalNameArr"){
      if(type =="cn-power"){
        this.$parent.params.BaseInfoProductDescription="有机认证的全谱工艺提取、喷雾干燥的粉末状 "+this.botanicalNameArr.toString()+" 植物提取物。"
      }
      if (
        type == "CSFMU-B" || type == "CSFMU-S" || type == "REXT" || 
        type == "F&V" || type == "FMU-D" || type == "HEXT" || 
        type == "OEXT" || type == "PCG" || type == "PCG-CSFMU-S" || 
        type == "SDNP" || type == "PCG-CSFMU-B"
      ) {
        this.params.productDescription="Spray dried, fine powdered botanical extract of "+this.botanicalNameArr.toString()+" that has been extracted in a medium temperature, full spectrum extraction process. "
      }
    }
    this[visible] = false;
    this[value] = "";
  },
  tagHandle(array,tagCotext,index){//tag标签点击事件
    console.info(array,tagCotext,index );
    this.$prompt('修改值-->'+tagCotext, '温馨提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
    }).then(({ value }) => {
      array.splice(index,1,value)
    }).catch(() => {
        
    });
  },
  leftInputValue(array,i){//往左移一位
    var tempOption = array[i - 1];
    this.$set(array, i - 1, array[i]);
    this.$set(array, i, tempOption)
  },
  rightInputValue(array,i){//往右移一位
    var tempOption = array[i + 1];
    this.$set(array, i + 1, array[i]);
    this.$set(array, i, tempOption)
  },
  //根据INCI name，botanical name，common name获取详情内容
  getAllName(name,arr){
    var specALLName = localStorage.getItem("specALLName");
    if(specALLName == null || specALLName == undefined){
      this.requestGetAllName(name,arr)
    }
    else{
      var commonNameList=JSON.parse(localStorage.getItem("commonNameList"))
      var inciNameList=JSON.parse(localStorage.getItem("inciNameList"))
      var botanicalNameList=JSON.parse(localStorage.getItem("botanicalNameList"))
      this.searchName(commonNameList,inciNameList,botanicalNameList,name,arr)
    }
    
  },
  requestGetAllName(name,arr){
    this.$api.handleSpec
      .getAllName(name)
      .then((data) => {
        console.log("getAllName By "+ arr +" Name")
        console.log(data)
        var commonNameList=[]
        var inciNameList=[]
        var botanicalNameList=[]
        data.forEach(element => {
          commonNameList.push(element.commonName)
          inciNameList.push(element.inciName)
          botanicalNameList.push(element.botanicalName)
        });

        localStorage.setItem("specALLName",this.formatTime(new Date(),"yyyy-MM-dd hh:mm"));
        localStorage.setItem("commonNameList",JSON.stringify(commonNameList));
        localStorage.setItem("inciNameList",JSON.stringify(inciNameList));
        localStorage.setItem("botanicalNameList",JSON.stringify(botanicalNameList));
        this.searchName(commonNameList,inciNameList,botanicalNameList,name,arr)
      })
      .catch((err) => {
        console.log(err);
      });
  },
  searchName(commonNameList,inciNameList,botanicalNameList,name,arr){
    let index = 0
    let index1 = 0
    let index2 = 0
    if(arr == "commonNameArr"){
      for (let item of commonNameList) {
        index = index + 1
        const element = item;
        if(element != null && element != ""){
          let sNameArr = element.split('_');
          if(sNameArr.length==1){
            sNameArr =  element.split(",");
          }
          let currentIndex = 0;
          let isTrue = false
          if(sNameArr.length>0){
            for (let iN=0; iN<sNameArr.length;iN++) {
              if(sNameArr[iN] == name){
                console.log("element")
                console.log(element)
                currentIndex = iN
                isTrue = true
                break;
              }
            }
            if(isTrue){
              let inclNameArr = inciNameList[index-1].split('_');
              let botanicalArr = botanicalNameList[index-1].split('_');
              if(inclNameArr.length>currentIndex){
                this["inclNameArr"].push(inclNameArr[currentIndex]);
              }
              if(botanicalArr.length>currentIndex){
                this["botanicalNameArr"].push(botanicalArr[currentIndex]);
              }
              break;
            }
          }
        }
      }
    }
    if(arr == "inclNameArr"){
      for (let item of inciNameList) {
        index1 = index1 + 1
        const element = item;
        if(element != null && element != ""){
          let sNameArr = element.split('_');
          if(sNameArr.length == 1){
            sNameArr =  element.split(",");
          }
          let currentIndex = 0;
          let isTrue = false
          if(sNameArr.length>0){
            for (let iN=0; iN<sNameArr.length;iN++) {
              if(sNameArr[iN] == name){
                console.log("element")
                console.log(element)
                currentIndex = iN
                isTrue = true
                break;
              }
            }
            if(isTrue){
              let commonNameArr = commonNameList[index1-1].split('_');
              let botanicalArr = botanicalNameList[index1-1].split('_');
              if(commonNameArr.length>currentIndex){
                this["commonNameArr"].push(commonNameArr[currentIndex]);
              }
              if(botanicalArr.length>currentIndex){
                this["botanicalNameArr"].push(botanicalArr[currentIndex]);
              }
              break;
            }
          }
        }
      }
    }
    if(arr == "botanicalNameArr"){
      for (let item of botanicalNameList) {
        index2 = index2 + 1
        const element = item;
        if(element != null && element != ""){
          let sNameArr = element.split('_');
          if(sNameArr.length==1){
            sNameArr =  element.split(",");
          }
          let currentIndex = 0;
          let isTrue = false
          if(sNameArr.length>0){
            for (let iN=0; iN<sNameArr.length;iN++) {
              if(sNameArr[iN] == name){
                console.log("element")
                console.log(element)
                currentIndex = iN
                isTrue = true
                break;
              }
            }
            if(isTrue){
              let commonNameArr = commonNameList[index2-1].split('_');
              let inclNameArr = inciNameList[index2-1].split('_');
              if(commonNameArr.length>currentIndex){
                this["commonNameArr"].push(commonNameArr[currentIndex]);
              }
              if(inclNameArr.length>currentIndex){
                this["inclNameArr"].push(inclNameArr[currentIndex]);
              }
              break;
            }
          }
        }
      }
    }
  },
  handleSelectName(val) {
    console.log(val);
    this.checkProductName = false;
  },
  handleSelectCode(val) {
    console.log(val);
    this.checkItemCode = false;
    this.check();
  },
  nameSearch(queryString, cb) {
    // var restaurants = this.tableData.map(v => {
    //   return { value: v.productName };
    // });
    var restaurants = this.$store.state.nameList;
    var results = queryString
      ? restaurants.filter(this.createFilter(queryString))
      : restaurants;
    // 调用 callback 返回建议列表的数据
    cb(results);
  },
  codeSearch(queryString, cb) {
    // var restaurants = this.tableData.map(v => {
    //   return { value: v.itemCode };
    // });
    var restaurants = this.$store.state.codeList;
    var results = queryString
      ? restaurants.filter(this.createFilter(queryString))
      : restaurants;
    // 调用 callback 返回建议列表的数据
    cb(results);
  },
  createFilter(queryString) {
    return (restaurant) => {
      return (
        restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
          -1 || restaurant.value.indexOf(queryString) != -1
      );
    };
  },
  productTypeChange(val) {

    this.validate("productType");
    
    if (val == "REXT") this.params.extractionRatio = "4:1";
    this.params.specificationYeastAndMold = "100";
    if (val === "OEXT") {
      this.params.certification =
        "Kosher certified, HALAL certified, NOP & EOS certified";
    } else {
      this.params.certification = "Kosher certified, HALAL certified";
    }
    
    this.changeShelflife(val);
    this.showData(val);
    this.params.liquidExtCustomerKey1 = ""
    this.params.liquidExtCustomerValue1 = ""
    this.params.liquidExtCustomerValue2 = ""
    switch (val) {
      case "F&V":
        this.params.specificationTotalPlateCount = "5000";
        break;
      case "FMU-D":
      case "HEXT":
      case "REXT":
      case "OEXT":
        // case "PCG-CSFM":
        this.params.specificationTotalPlateCount = "3000";
        break;
      case "PCG":
      case "PCG-CSFM":
        this.params.specificationTotalPlateCount = "100";
        break;
      case "SHXTO":
        this.initSHXTO();
        break;
      case "Liquid EXT-B":
      case "Liquid EXT-S":
        console.log("select : Liquid EXT-S");
        this.initLiquidEXT();
        this.params.liquidExtCustomerKey1 = "Identification"
        this.params.liquidExtCustomerValue1 = "Standard plate"
        this.params.liquidExtCustomerValue2 = "HPTLC"
        break;
      case "EHXTO":
        this.initEHXTO();
        break;
      case "SDNP-L":
        this.params.color = "Compares to standard";
        this.params.odor = "Compares to standard";
        this.params.taste = "Compares to standard";
        this.params.productDefinition = "Liquid botanical extract solution prepared by mixing full spectrum bioactive extract with solvent. Original extract is derived from a proprietary, closed system, temperature controlled process in which the majority of the active phytocompounds are retained for full bioactivity.";
        this.params.analysisRangePh = "3.5-7.5";
        this.params.analysisMethodPh = "3.5-7.5";
        this.params.testMethodTotalColiforms = "";
        break;
      case "SDNP":
        this.params.physicalProperties="Blend"
        break;
      default:
        return "";
    } 
    

  },
  appearanceTypeChange(){
    if(this.params.appearance=="Powder"){
      this.params.specificationBulkDensity="0.25-0.75"
    }
    else{
      this.params.specificationBulkDensity="0.40-0.85"
    }
  },
  changeShelflife(type,shelfile){
    //shelf life setting
    console.log(type)
    console.log(shelfile)
    console.info(type,shelfile)
    if(shelfile){
      this.params.shelflife = shelfile;
      return;
    }
    
    switch(type){
      case "CSFMU-S":
      case "CSFMU-B":
      case "SDNP":
      case "F&V":
      case "FMU-D":
      case "HEXT":
      case "PCG":
      case "REXT":
      case "OEXT":
      case "PCG-CSFMU-S":
      case "PCG-CSFMU-B":
        this.params.shelflife = "Three years unopened. Product is sensitive to heat and moisture.";
        this.params.shelflifeNumber = "36"
        break;
      case "Liquid EXT-B":
      case "Liquid EXT-S":
      case "SDNP-L":
        this.params.shelflife = "2 years unopened";
        this.params.shelflifeNumber = "24"
        break;
      case "SHXTO":
      case "EHXTO":
          this.params.shelflife = "Two years unopened in the original package.";
          this.params.shelflifeNumber = "24"
          break;
      case "TY-P":
          this.params.shelflife = "未开封情况下三年保质期。产品对热和潮湿敏感。";
          this.params.shelflifeNumber = "36"
          break;
      default:
        this.params.shelflife = "Three years unopened. Product is sensitive to heat and moisture.";
        this.params.shelflifeNumber = "36"
        break;
    }

    if(this.params.productType=='TY-P')
    {
      this.params.storage = "避光，密封，在干燥处保存。";
    }
    else if(this.params.productType!='Liquid EXT-S'&&this.params.productType!='Liquid EXT-B'&&this.params.productType!='SDNP-L'){ 
      if(!this.params.specificationEcoli){
        this.params.specificationEcoli="Negative";
        this.params.testMethodTotalColiforms = "FDA-BAM";
      }
      if(!this.params.specificationSalmonella){
        this.params.specificationSalmonella="Negative";
        this.params.testMethodTotalColiforms = "FDA-BAM";
      }
      if(!this.params.specificationStaphAureus){
        this.params.specificationStaphAureus="Negative";
        this.params.testMethodTotalColiforms = "FDA-BAM";
      }
      this.params.storage = "Preserve in airtight containers in a cool area, dry area.";
    }else{
      this.params.testMethodTotalColiforms = "FDA-BAM";
      this.params.storage = "Preserve in airtight containers in a cool area.";
      this.params.specificationEcoli="";
      this.params.specificationSalmonella="";
      this.params.specificationStaphAureus="";
    }
  },
  logout(type="en") {
    let token = localStorage.getItem("specToken");
    let id = JSON.parse(localStorage.getItem("specUserInfo")).id;
    this.$api
      .logout({ id, token })
      .then(() => {
        if(type=="en"){
          this.$router.replace({ path: "/login" });
          this.$message({ type: "success", message: "Logout SuccessFully" });
        }
        else{
          this.$router.replace({ path: "/cn-login" });
          this.$message({ type: "success", message: "退出成功" });
        }
        localStorage.clear("specToken");
        localStorage.clear("specUserInfo");
      })
      .catch((err) => {
        console.log(err);
      });
  },
  logoutHandle() {
    this.$confirm("Are you sure to quit?", "Tips", {
      confirmButtonText: "Confrim",
      cancelButtonText: "Cancel",
      distinguishCancelAndClose: true,
      type: "warning",
    }).then(() => {
      this.logout();
    });
  },
  logoutCnSpecHandle() {
    this.$confirm("确定要退出吗?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      distinguishCancelAndClose: true,
      type: "warning",
    }).then(() => {
      this.logout("cn");
    });
  },
  validate(item, type) {
    switch (item) {
      case "productType":
        {
          this.checkProductType = this.params.productType.trim()
            ? false
            : true;
        }
        break;
      case "productName":
        {
          this.checkProductName = this.params.productName.trim()
            ? false
            : true;
        }
        break;
      case "itemCode":
        {
          this.checkItemCode = this.params.itemCode.trim() ? false : true;
          if (this.itemCodeExist == 0) {
            this.checkItemCode = true;
          }
          if (type == "change") {
            // console.log(123);
            this.hasVerifyItemCode = false;
          }
          if (type == "check" && !this.hasVerifyItemCode) {
            this.check();
          }
        }
        break;
      default:
        break;
    }

  },
  back() {
    this.$router.go(-1);
    // this.$router.push({ path: "/home" });
  },
  backcn() {
    this.$router.push({ path: "/cn-home" });
  },
  confirm(type) {
    let confirmBtn = type == "Continue Edit" ? "Back Home" : "Confirm";
    this.$confirm("Are you sure back to home?", "Tips", {
      confirmButtonText: confirmBtn,
      cancelButtonText: type,
      distinguishCancelAndClose: true,
      type: "warning",
    })
      .then(() => {
        this.$router.push({ path: "/home" });
      })
      .catch((action) => {
        if (type == "New" && action == "cancel") {
          this.titleType = "Add";
          this.newItemType = "New";
          this.params = {
            productName: "",
            itemCode: "",
            productDescription: "",
            generalSpecificatio: "",
            createTime: "",
            productType: "",
            ingredients: "",
            productGrade: "",
            inciName: "",
            botanicalName: "",
            commonOtherName: "",
            partUsed: "",
            extractionRatio: "",
            importantConstitueInPlant: "",
            appearance: "",
            solubility: "Water soluble/dispersible",
            homogeneity: "",
            extractionProcess: "",
            solventForExtracti: "Water",
            carrier: "",
            pesticides: "Conforms to USP&lt;565&gt;",
            certification: "Kosher certified, HALAL certified",
            specificationStandard1: "",
            specificationStandard2: "",
            specificationStandard3: "",
            specificationMeshSize: "",
            specificationLossonDrying: "6",
            specificationBulkDensity: "0.25-0.75",
            specificationHptlcIdentification: "",
            specificationTotalHeavyMetals: "",
            specificationColor: "",
            specificationOrdor: "Compares to standard",
            specificationTaste: "Compares to standard",
            testMethodStandard1: "DracoUV-",
            testMethodStandard2: "DracoHPLC-",
            testMethodStandard3: "",
            testMethodMeshSize: "",
            testMethodLossOnDrying: "",
            testMethodBulkDensity: "",
            testMethodHptlcIdentification: "",
            testMethodHeavyMetals: "",
            testMethodColor: "",
            testMethodOrdor: "Organoleptic",
            testMethodTaste: "Organoleptic",
            specificationTotalPlateCount: "",
            specificationYeastAndMold: "100",
            specificationEcoli: "",
            specificationSalmonella: "",
            specificationStaphAureus: "",
            specificationTotalColiforms: "",
            testMethodTotalPlateCount: "",
            testMethodYeastAndMold: "",
            testMethodEcoli: "",
            testMethodSalmonella: "",
            testMethodTaphAureus: "",
            testMethodTotalColiforms: "",
            shelflife: "",
            stotage: "",
          };
        }
      });
  },
  cnConfirm(type) {
    let confirmBtn = type == "Continue Edit" ? "返回首页" : "确认";
    this.$confirm("你确定要返回首页吗?", "提示", {
      confirmButtonText: confirmBtn,
      cancelButtonText: "取消",
      distinguishCancelAndClose: true,
      type: "warning",
    })
      .then(() => {
        this.$router.push({ path: "/cn-home" });
      })
      .catch((action) => {
        if (type == "New" && action == "cancel") {
          this.titleType = "添加";
          this.newItemType = "新增";
          this.params = {
            productName: "",
            itemCode: "",
            productDescription: "",
            generalSpecificatio: "",
            createTime: "",
            productType: "",
            ingredients: "",
            productGrade: "",
            inciName: "",
            botanicalName: "",
            commonOtherName: "",
            partUsed: "",
            extractionRatio: "",
            importantConstitueInPlant: "",
            appearance: "",
            solubility: "溶于/可分散于水",
            homogeneity: "",
            extractionProcess: "",
            solventForExtracti: "水",
            carrier: "",
            pesticides: "符合USP <565>",
            certification: "Kosher certified, HALAL certified",
            specificationStandard1: "",
            specificationStandard2: "",
            specificationStandard3: "",
            specificationMeshSize: "",
            specificationLossonDrying: "6",
            specificationBulkDensity: "0.25-0.75",
            specificationHptlcIdentification: "",
            specificationTotalHeavyMetals: "",
            specificationColor: "",
            specificationOrdor: "符合标准",
            specificationTaste: "符合标准",
            testMethodStandard1: "DracoUV-",
            testMethodStandard2: "DracoHPLC-",
            testMethodStandard3: "",
            testMethodMeshSize: "",
            testMethodLossOnDrying: "",
            testMethodBulkDensity: "",
            testMethodHptlcIdentification: "",
            testMethodHeavyMetals: "",
            testMethodColor: "",
            testMethodOrdor: "感官评定",
            testMethodTaste: "感官评定",
            specificationTotalPlateCount: "",
            specificationYeastAndMold: "100",
            specificationEcoli: "",
            specificationSalmonella: "",
            specificationStaphAureus: "",
            specificationTotalColiforms: "",
            testMethodTotalPlateCount: "",
            testMethodYeastAndMold: "",
            testMethodEcoli: "",
            testMethodSalmonella: "",
            testMethodTaphAureus: "",
            testMethodTotalColiforms: "",
            shelflife: "",
            shelflifeNumber: "",
            stotage: "",
          };
        }
        // else if(type == "Continue Edit") {
        //   this.$router.go(0)
        // }
      });
  },
  async checkItemRes() { 
    let res = true;
    let el = document.querySelector(".app-new-item");
    if (!this.params.itemCode.trim()) {
      this.checkItemCode = true;
      el.scrollTop = this.$refs.itemCode.$el.offsetTop - 70;
      res = false;
    }
    if (!this.params.productName.trim()) {
      this.checkProductName = true;
      el.scrollTop = this.$refs.productName.$el.offsetTop - 70;
      res = false;
    }
    if (!this.params.productType.trim()) {
      this.checkProductType = true;
      el.scrollTop = this.$refs.productType.$el.offsetTop - 70;
      res = false;
    }
    if (this.itemCodeExist == 0) {
      this.$message({ type: "error", message: "Item Code Already Exists" });
      this.checkItemCode = true;
      el.scrollTop = this.$refs.itemCode.$el.offsetTop - 70;
      res = false;
    }
    
    return new Promise((resolve)=>{ 
      resolve(res)
    });
  },
  async handleNew(type,verifyType=0) {
    //console.log("params"); 
    //this.params.confidential=false;  
    if(this.params.customFormula && this.params.customer==''){
      this.$message({
        type: "info",
        message: "Please fill in Customer",
      });
      return false
    }
    if(this.params.confidential==null){
      this.params.confidential=false;
    } 
    this.params.baseInfoCustomField = JSON.stringify(this.BaseInfoCustomFieldAttr);
    this.params.ingredients = this.ingredientsArr.join("_");
    this.params.inciName = this.inclNameArr.join("_");
    this.params.botanicalName = this.botanicalNameArr.join("_");
    this.params.commonOtherName = this.commonOtherNameArr.join("_");
    this.params.partUsed = this.partUsedArr.join("_"); 
    this.params.percentage = this.percentageArr.join("_");
    this.params.processing = this.processingArr.join("_");
    // this.params.majorConstituentsInNativeHerbs = this.majorConstituentsInNativeHerbsArr.join(
    //   "_"
    // );
    //this.params.preservative = this.preservativeArr.join("_");
    this.params.commonName = this.commonNameArr.join("_");
    this.params.fattyAcidComposition = JSON.stringify(this.fattyAcidCompositionArr)
    this.params.shTest = JSON.stringify(this.shTestArr)
    this.params.noteTableData = JSON.stringify(this.noteTableData)
    this.params.newNoteArr = JSON.stringify(this.newNoteArr)
    let revNumber = 0
    this.noteTableData.forEach(item=>{
      if(item.isComplete){
        revNumber ++
      }
    })
    this.params.revNumber = revNumber
    this.params.genSpecCustomField = JSON.stringify(this.genSpecCustomFieldAttr) 
    this.params.analysisCustomField = JSON.stringify(this.analysisCustomFieldAttr) 
    this.params.micTestsCustomField = JSON.stringify(this.micTestsCustomFieldAttr) 
    this.params.tableInputCustomField = JSON.stringify(this.tableInputCustomFieldAttr)
    let res = await this.checkItemRes();
    if (res == false) return;
    this.loading = true;
    delete this.params.verified;
    console.log("=======this.params----handleNew=======")
    console.log(this.params)
    let jsonStr = JSON.stringify(this.params);
    this.formData.productName = this.params.productName;
    this.formData.itemCode = this.params.itemCode;
    this.formData.productDescription = this.params.productDescription;
    this.formData.generalSpecificatio = this.params.generalSpecificatio;
    this.formData.productType = this.params.productType;
    this.formData.productSubType = this.params.productSubType;
    this.formData.ingredients = this.params.ingredients;
    this.formData.inciName = this.params.inciName;
    this.formData.botanicalName = this.params.botanicalName;
    this.formData.commonName = this.params.commonName;
    this.formData.commonOtherName = this.params.commonOtherName;
    this.formData.partUsed = this.params.partUsed;
    this.formData.appearance = this.params.appearance;
    this.formData.verified = this.params.verified;
    this.formData.jsonValue = jsonStr;

    console.log("this.formData")
    console.log(this.formData)
    if (this.newItemType == "New") {
      this.$api.handleSpec
        .addItem(this.formData)
        .then(() => {
          this.loading = false;
          this.$message({
            type: "success",
            message: this.newItemType +" SuccessFul",
          });
          this.titleType = "Add";
          this.confirm(type);
          this.revDateTime = base.methods.getLocalNowTime()
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    } 
    else if (this.newItemType == "Edit") {
      this.params.revDate.push(this.revDateTime)
      jsonStr = JSON.stringify(this.params);
      this.formData.jsonValue = jsonStr;
      this.formData.id = this.params.productId;
      if(verifyType == 1){
        this.formData.verifiedList = 1
      }
      else if(verifyType == 2){
        this.formData.verifiedList = 0
      }
      console.log("保存的数据");
      console.log(this.formData);
      this.$api.handleSpec
        .editItem(this.formData)
        .then(() => { 
          this.loading = false;
          this.$message({
            type: "success",
            message: this.newItemType +" SuccessFul",
          });
          this.confirm(type);
          this.revDateTime = base.methods.getLocalNowTime()
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  getData(params) {
    this.loading = true;
    if(!this.otherType){
      this.loading = false;

    }else{
      this.getSpecData(params);
    }
  },
  getSpecData(params){
    this.$api.handleSpec
      .getDetail(params)
      .then((data) => {
        this.hasVerifyItemCode = true;
        this.loading = false;
        if (data.productType == "F&amp;M") {
          data.productType = "F&V";
        } 
        console.info("data");
        console.info(data);
        if(data.confidential=="true"){
          data.confidential=true; 
        }else{
          data.confidential=false;
        }
        this.showDataInfo(data.productType);
        // this.params = data;
        if(data.jsonValue==undefined){
          this.$message('Data does not exist');
          return false
        }
        this.params = JSON.parse(data.jsonValue);
        if(this.params.baseInfoCustomField){
          this.BaseInfoCustomFieldAttr = JSON.parse(this.params.baseInfoCustomField)
        }
        if(this.params.revNumber == undefined){
          this.params.revNumber = 0
        }
        if(this.params.revDate == undefined){
          this.params.revDate = []
        }
        console.log('this.params.revDate')
        console.log(this.params.revDate)

        console.log('this.params.revNumber')
        console.log(this.params.revNumber)

        if(this.params.newNoteArr == undefined){
          this.params.newNoteArr = []
          this.newNoteArr = []
        }
        else{
          this.newNoteArr = JSON.parse(this.params.newNoteArr);
        }
        console.log('this.newNoteArr')
        console.log(this.newNoteArr)

        // console.info('aaaa:',this.params.inciName)
        this.params.productId = data.id;
        //this.params.specificationEcoli="";
        this.strSplit(this.params.ingredients, "ingredientsArr");
        this.strSplit(this.params.inciName, "inclNameArr");
        // console.log("this.params.inciName");
        // console.log(this.params.inciName);
        this.strSplit(this.params.botanicalName, "botanicalNameArr");
        this.strSplit(this.params.commonOtherName, "commonOtherNameArr");
        // console.info('this.params.commonOtherName:',this.params.commonOtherName)
        this.strSplit(this.params.partUsed, "partUsedArr");
        this.strSplit(this.params.percentage, "percentageArr");
        this.strSplit(this.params.processing, "processingArr");
        // this.strSplit(
        //   this.params.majorConstituentsInNativeHerbs,
        //   "majorConstituentsInNativeHerbsArr"
        // );
        //把_替换成,

        if(this.params.majorConstituentsInNativeHerbs!=null){
          this.params.majorConstituentsInNativeHerbs=this.params.majorConstituentsInNativeHerbs.replace(/_/g,",");
        }
        if(data.productType=="SDNP-L"){
          if(this.params.odor==""||this.params.odor==null){
            this.params.odor="Compares to standard";
          }
          if(this.params.taste==""||this.params.taste==null){
            this.params.taste="Compares to standard";
          }
        }
        if(data.productType=="SHXTO"){
          if(!this.params.testMethodTotalColiforms){
            this.params.testMethodTotalColiforms="FDA-BAM";
          }
        }
        if(this.params.preservative!=null){
          this.params.preservative=this.params.preservative.replace(/_/g,",");
          this.params.preservative=this.params.preservative.replace(/，/g,",");
        }
        //this.strSplit(this.params.preservative, "preservativeArr");
        this.strSplit(this.params.commonName, "commonNameArr");
        console.log("getDetail");
        console.log(data);
        this.formData.verifiedList = data.verifiedList
        console.log('this.formData.verifiedList')
        console.log(this.formData.verifiedList)
        //根据ingredientsTags 更新 botanicalNameArr
        // if(data.productType=='FMU-D'||data.productType=='CSFMU'){
        if(data.productType=='CSFMU'){
          this.botanicalNameArr = this.ingredientsTags(0);
          this.commonOtherNameArr =  this.ingredientsTags(1);
          this.partUsedArr = this.ingredientsTags(2);
        }else if(data.productType=='SDNP-L'||data.productType=='Liquid EXT-S'||data.productType=='Liquid EXT-B'){
          //如果是空则等于100
          if(this.params.analysisRangeYeastMold==""){
            this.params.analysisRangeYeastMold="100";
          } 
          if(this.params.specificationEcoli=="Negative"){
            this.params.specificationEcoli="";
          } 
          if(this.params.specificationSalmonella=="Negative"){
            this.params.specificationSalmonella="";
          } 
          if(this.params.specificationStaphAureus=="Negative"){
            this.params.specificationStaphAureus="";
          } 
        }
        if(this.params.physicalProperties==""){
          if(data.productType=="CSFMU-B"||data.productType=="FMU-D"){
            this.params.physicalProperties= "Blend";
          }else if(data.productType=="CSFMU-S"||data.productType=="PCG-CSFMU-S"||data.productType=="F&V"||data.productType=="HEXT"||data.productType=="REXT"||data.productType=="OEXT"||data.productType=="PCG"||data.productType=="PCG-CSFMU-S"){
            this.params.physicalProperties= "Single";
          }else if(data.productType=="Liquid EXT-S"||data.productType=="Liquid EXT-B"){
            this.params.physicalProperties= "Liquid";
          }else if(data.productType=="SHXTO"){
            this.params.physicalProperties= "Oil";
          }else if(data.productType=="EHXTO"){
            this.params.physicalProperties= "Essential oil";
          }
          if(this.params.appearance=="granular"){
            this.params.physicalProperties="Granulate";
          }
        }
        localStorage.setItem('local_noteTableData',this.params.noteTableData);

        this.noteTableData = JSON.parse(this.params.noteTableData);
        if(!this.noteTableData){
          this.noteTableData = [{"revisionDate":"","requestedBy":"","itemsRevised":"","editedBy":"","reason":"","approvedBy":"","isComplete":false}];
        }
        console.log('this.noteTableData')
        this.noteTableData.forEach(item=>{
          if(item.reason == undefined){
            item.reason = ''
          }
          if(item.isComplete == undefined){
            item.isComplete = false
          }
          console.log(item.reason)
          console.log(item.isComplete)
        })
        this.noteTableData = JSON.parse(JSON.stringify(this.noteTableData))
        let revNumber = 0
        this.noteTableData.forEach(item=>{
          if(item.isComplete){
            revNumber ++
          }
        })
        this.params.revNumber = revNumber
        console.log(this.noteTableData)
        //TODO:shTestArr push 
        if (!this.params.shTest || this.params.shTest === null) {
          
          this.shTestArr.push({
            testLimitAcidValue: this.params.testLimitAcidValue,
            testLimitPeroxideValue: this.params.testLimitPeroxideValue,
            testLimitSaponificationValue: this.params.testLimitSaponificationValue,
            testLimitUnsaponifiableMaster: this.params.testLimitUnsaponifiableMaster,
            testLimitMoistureVolatiles: this.params.testLimitMoistureVolatiles,
            testLimitLead: this.params.testLimitLead,
            testLimitArsenic: this.params.testLimitArsenic,
            testLimitCadmium: this.params.testLimitCadmium,
            testLimitMercury: this.params.testLimitMercury,
            testLimitPesticides: this.params.testLimitPesticides,
            testMethodAcidValue: this.params.testMethodAcidValue,
            testMethodPeroxideValue: this.params.testMethodPeroxideValue,
            testMethodSaponificationValue: this.params.testMethodSaponificationValue,
            testMethodUnsaponifiableMaster: this.params.testMethodUnsaponifiableMaster,
            testMethodMoistureVolatiles: this.params.testMethodMoistureVolatiles,
            testMethodLead: this.params.testMethodLead,
            testMethodArsenic: this.params.testMethodArsenic,
            testMethodCadmium: this.params.testMethodCadmium,
            testMethodMercury: this.params.testMethodMercury,
            testMethodPesticides: this.params.testMethodPesticides,
            testLimitHeavyMetals:this.params.testLimitHeavyMetals,
          });
        } else {
          this.shTestArr = JSON.parse(this.params.shTest);
          
        }

        if (!this.params.fattyAcidComposition || this.params.fattyAcidComposition === null) {
          this.fattyAcidCompositionArr.push({
            fattryLimitPalmitica: this.params.fattryLimitPalmitica,
            fattryLimitPalmitoleic: this.params.fattryLimitPalmitoleic,
            fattryLimitStearic: this.params.fattryLimitStearic,
            fattryLimitOleicVaccenic: this.params.fattryLimitOleicVaccenic,
            fattryLimitLinoleic: this.params.fattryLimitLinoleic,
            fattryLimitALinolenic: this.params.fattryLimitALinolenic,
            fatterLimitOthers: this.params.fatterLimitOthers,
            fattryMethodPalmitica: this.params.fattryMethodPalmitica,
            fattryMethodPalmitoleic: this.params.fattryMethodPalmitoleic,
            fattryMethodStearic: this.params.fattryMethodStearic,
            fattryMethodOleicVaccenic: this.params.fattryMethodOleicVaccenic,
            fattryMethodLinoleic: this.params.fattryMethodLinoleic,
            fattryMethodALinolenic: this.params.fattryMethodALinolenic,
            fatterMethodOthers: this.params.fatterMethodOthers,
          });
        } else {
          this.fattyAcidCompositionArr = JSON.parse(this.params.fattyAcidComposition)
        }
        //非空默认值
        this.setEmptyDefaultValue();
        this.changeShelflife(this.params.productType,this.params.shelflife);
        this.params.specificationYeastAndMold = this.params.specificationYeastAndMold.replace("<","")
        this.params.specificationYeastAndMold = this.params.specificationYeastAndMold.replace("/g","")
        if (this.params.genSpecCustomField) {
          this.genSpecCustomFieldAttr=JSON.parse(this.params.genSpecCustomField)
        }
        if (this.params.analysisCustomField) {
          this.analysisCustomFieldAttr=JSON.parse(this.params.analysisCustomField)
        }
        if (this.params.micTestsCustomField) {
          this.micTestsCustomFieldAttr=JSON.parse(this.params.micTestsCustomField)
        }
        if (this.params.tableInputCustomField) {
          this.tableInputCustomFieldAttr=JSON.parse(this.params.tableInputCustomField)
        }
        this.revDateTime = data.updateTime

        //开放最后一条可编辑
        var note = this.noteTableData[this.noteTableData.length-1];
        note.isComplete = false;

        //如果是print page,则判断是否verifly,true显示，false提示
        if(params.aloneprint && !data.verified){
          this.$router.push('/no-pdf')
        }
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  async handleCnSpecNew(type,verifyType=0) {
    this.params.baseInfoCustomField = JSON.stringify(this.$parent.BaseInfoCustomFieldAttr);
    this.params.ingredients = this.ingredientsArr.join("_");
    this.params.inciName = this.inclNameArr.join("_");
    this.params.botanicalName = this.botanicalNameArr.join("_");
    this.params.commonOtherName = this.commonOtherNameArr.join("_");
    this.params.partUsed = this.partUsedArr.join("_");
    this.params.percentage = this.percentageArr.join("_");
    this.params.processing = this.processingArr.join("_");
    this.params.majorConstituentsInNativeHerbs = this.majorConstituentsInNativeHerbsArr.join(
      "_"
    );
    this.params.preservative = this.preservativeArr.join("_");
    this.params.commonName = this.commonNameArr.join("_");
    this.params.fattyAcidComposition = JSON.stringify(this.fattyAcidCompositionArr)
    this.params.shTest = JSON.stringify(this.shTestArr)
    this.params.noteTableData = JSON.stringify(this.noteTableData)
    this.params.newNoteArr = JSON.stringify(this.newNoteArr)
    let revNumber = 0
    this.noteTableData.forEach(item=>{
      if(item.isComplete){
        revNumber ++
      }
    })
    this.params.revNumber = revNumber
    let inciName = this.params.inciName;
    // let res = await this.checkItemRes();
    // if (res == false) return;
    this.params.inciName = inciName;
    this.loading = true;

    this.params.genSpecCustomField = JSON.stringify(this.genSpecCustomFieldAttr) 
    this.params.analysisCustomField = JSON.stringify(this.analysisCustomFieldAttr) 
    this.params.micTestsCustomField = JSON.stringify(this.micTestsCustomFieldAttr)
    this.params.tableInputCustomField = JSON.stringify(this.tableInputCustomFieldAttr)

    delete this.params.verified;
    console.log("=======this.params=====handleCnSpecNew=======")
    console.log(this.params)
    let jsonStr = JSON.stringify(this.params);
    this.formData.productName = this.params.productName;
    this.formData.itemCode = this.params.itemCode;
    this.formData.lot = '';
    this.formData.jsonValue = jsonStr;
    if(verifyType == 1){
      this.formData.verifiedList = 1
      this.params.verifiedList = 1
    }
    else if(verifyType == 2){
      this.formData.verifiedList = 0
    }
    else{
      this.formData.verifiedList = 0
    }
    console.log(this.formData);
    if (this.newItemType == "New") {
      this.$api.handleCnSpec
        .addItem(this.formData)
        .then(() => {
          this.loading = false;
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.titleType = "添加";
          this.cnConfirm(type);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    } else if (this.newItemType == "Edit") {
      // this.params.revDate.push(this.revDateTime)
      jsonStr = JSON.stringify(this.params);
      this.formData.jsonValue = jsonStr;
      this.formData.id = this.params.productId;
      console.info('Confidential:',this.params.confidential);
      console.info('3333this.this.params:',this.params.inciName);
      this.$api.handleCnSpec
        .editItem(this.formData)
        .then(() => {
          this.loading = false;
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.cnConfirm(type);
          console.log("verifyType======"+verifyType)
          if(verifyType == 2){
            console.log("this.$parent.params.revNumber======"+this.$parent.params.revNumber)
            this.$parent.params.revNumber=parseInt(this.$parent.params.revNumber)+1;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  async handleCnSpecLNew(type,verifyType=0) {
    this.form.baseInfoCustomField = JSON.stringify(this.$parent.BaseInfoCustomFieldAttr);
    this.form.inciName = this.inclNameArr.join("_");
    this.form.commonName = this.commonNameArr.join("_");
    this.form.botanicalName = this.botanicalNameArr.join("_");
    this.form.nativeHerbs = this.nativeHerbsArr.join("_");
    this.form.partUsed = this.partUsedArr.join("_");
    this.form.percentage = this.percentageArr.join("_");

    this.form.noteTableData = JSON.stringify(this.noteTableData)
    this.form.newNoteArr = JSON.stringify(this.newNoteArr)
    let revNumber = 0
    this.noteTableData.forEach(item=>{
      if(item.isComplete){
        revNumber ++
      }
    })
    this.form.revNumber = revNumber
    // let inciName = this.form.inciName;
    
    // this.form.inciName = inciName;
    this.loading = true;
    this.form.genSpecCustomField = JSON.stringify(this.genSpecCustomFieldAttr) 
    this.form.analysisCustomField = JSON.stringify(this.analysisCustomFieldAttr)
    this.form.tableInputCustomField = JSON.stringify(this.tableInputCustomFieldAttr)

    //newNote
    this.form.newNote = this.newNote
    console.info("this.formatDate.newNote",this.newNote)
    
    //delete this.form.verified;
    console.log("=======this.form=======")
    console.log(this.form)
    let jsonStr = JSON.stringify(this.form);
    this.formData.productName = this.form.BaseInfoProductName;
    this.formData.itemCode = this.form.BaseInfoItemCode;
    this.formData.lot = '';
    this.formData.jsonValue = jsonStr;

     
     
    if(verifyType == 1){
      this.formData.verifiedList = 1
      this.form.verifiedList = 1
    }
    else if(verifyType == 2){
      this.formData.verifiedList = 0
    }
    else{
      this.formData.verifiedList = 0
    }
    console.log(this.formData);
    if (this.newItemType == "New") {
      this.$api.handleCnSpec
        .addItem(this.formData)
        .then(() => {
          this.loading = false;
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.titleType = "添加";
          this.cnConfirm(type);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    } else if (this.newItemType == "Edit") {
      // this.form.revDate.push(this.revDateTime)
      jsonStr = JSON.stringify(this.form);
      this.formData.jsonValue = jsonStr;
      this.formData.id = this.form.productId;
      this.$api.handleCnSpec
        .editItem(this.formData)
        .then(() => {
          this.loading = false;
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.cnConfirm(type);
          console.log("verifyType======"+verifyType)
          if(verifyType == 2){
            console.log("this.$parent.params.revNumber======"+this.$parent.params.revNumber)
            this.$parent.params.revNumber=parseInt(this.$parent.params.revNumber)+1;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  async handleCnSpecENew(type,verifyType=0) {
    this.form.baseInfoCustomField = JSON.stringify(this.$parent.BaseInfoCustomFieldAttr);
    this.form.commonName = this.commonNameArr.join("_");
    this.form.inciName = this.inclNameArr.join("_");
    this.form.botanicalName = this.botanicalNameArr.join("_");
    this.form.partUsed = this.partUsedArr.join("_");

    this.form.noteTableData = JSON.stringify(this.noteTableData)
    this.form.newNoteArr = JSON.stringify(this.newNoteArr)
    let revNumber = 0
    this.noteTableData.forEach(item=>{
      if(item.isComplete){
        revNumber ++
      }
    })
    this.form.revNumber = revNumber
    // let inciName = this.form.inciName;
    
    // this.form.inciName = inciName;
    this.loading = true;
    this.form.genSpecCustomField = JSON.stringify(this.genSpecCustomFieldAttr) 
    this.form.analysisCustomField = JSON.stringify(this.analysisCustomFieldAttr) 
    this.form.micTestsCustomField = JSON.stringify(this.micTestsCustomFieldAttr) 
    this.form.tableInputCustomField = JSON.stringify(this.tableInputCustomFieldAttr)

    //delete this.form.verified;
    console.log("=======this.form=======")
    console.log(this.form)
    let jsonStr = JSON.stringify(this.form);
    this.formData.productName = this.form.productName;
    this.formData.itemCode = this.form.itemCode;
    this.formData.lot = '';
    this.formData.jsonValue = jsonStr;
    if(verifyType == 1){
      this.formData.verifiedList = 1
      this.form.verifiedList = 1
    }
    else if(verifyType == 2){
      this.formData.verifiedList = 0
    }
    else{
      this.formData.verifiedList = 0
    }
    console.log(this.formData);
    if (this.newItemType == "New") {
      this.$api.handleCnSpec
        .addItem(this.formData)
        .then(() => {
          this.loading = false;
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.titleType = "添加";
          this.cnConfirm(type);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    } else if (this.newItemType == "Edit") {
      jsonStr = JSON.stringify(this.form);
      this.formData.jsonValue = jsonStr;
      this.formData.id = this.form.productId;
      this.$api.handleCnSpec
        .editItem(this.formData)
        .then(() => {
          this.loading = false;
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.cnConfirm(type);
          console.log("verifyType======"+verifyType)
          if(verifyType == 2){
            console.log("this.$parent.params.revNumber======"+this.$parent.params.revNumber)
            this.$parent.params.revNumber=parseInt(this.$parent.params.revNumber)+1;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  async handleCnSpecSNew(type,verifyType=0) {
    this.form.baseInfoCustomField = JSON.stringify(this.$parent.BaseInfoCustomFieldAttr);
    this.form.commonName = this.commonNameArr.join("_");
    this.form.inciName = this.inclNameArr.join("_");
    this.form.botanicalName = this.botanicalNameArr.join("_");
    this.form.partUsed = this.partUsedArr.join("_");

    this.form.noteTableData = JSON.stringify(this.noteTableData)
    this.form.newNoteArr = JSON.stringify(this.newNoteArr)
    let revNumber = 0
    this.noteTableData.forEach(item=>{
      if(item.isComplete){
        revNumber ++
      }
    })
    this.form.newNote = JSON.stringify(this.newNote)
    this.form.revNumber = revNumber
    // let inciName = this.form.inciName;
    
    // this.form.inciName = inciName;
    this.loading = true;
    this.form.genSpecCustomField = JSON.stringify(this.genSpecCustomFieldAttr) 
    this.form.analysisCustomField = JSON.stringify(this.analysisCustomFieldAttr) 
    this.form.fattyCustomField = JSON.stringify(this.fattyCustomFieldAttr) 
    this.form.micTestsCustomField = JSON.stringify(this.micTestsCustomFieldAttr) 
    this.form.tableInputCustomField = JSON.stringify(this.tableInputCustomFieldAttr)

    //delete this.form.verified;
    console.log("=======this.form=======")
    console.log(this.form)
    let jsonStr = JSON.stringify(this.form);
    this.formData.productName = this.form.BaseInfoProductName;
    this.formData.itemCode = this.form.BaseInfoItemCode;
    this.formData.lot = '';
    this.formData.jsonValue = jsonStr;

   
    if(verifyType == 1){
      this.formData.verifiedList = 1
      this.form.verifiedList = 1
    }
    else if(verifyType == 2){
      this.formData.verifiedList = 0
    }
    else{
      this.formData.verifiedList = 0
    }
    console.log(this.formData);
    if (this.newItemType == "New") {
      this.$api.handleCnSpec
        .addItem(this.formData)
        .then(() => {
          this.loading = false;
          this.$message({
            type: "success",
            message: "新增成功",
          });
          this.titleType = "添加";
          this.cnConfirm(type);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    } else if (this.newItemType == "Edit") {
      jsonStr = JSON.stringify(this.form);
      this.formData.jsonValue = jsonStr;
      this.formData.id = this.form.productId;
      this.$api.handleCnSpec
        .editItem(this.formData)
        .then(() => {
          this.loading = false;
          this.$message({
            type: "success",
            message: "修改成功",
          });
          this.cnConfirm(type);
          console.log("verifyType======"+verifyType)
          if(verifyType == 2){
            console.log("this.$parent.params.revNumber======"+this.$parent.params.revNumber)
            this.$parent.params.revNumber=parseInt(this.$parent.params.revNumber)+1;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  getCnSpecData(params){
    this.$api.handleCnSpec
      .getDetail(params)
      .then((data) => {
        this.hasVerifyItemCode = true;
        this.loading = false; 
        console.info("getCnSpecPPPPPPPPData");
        console.info(data);
        this.params = JSON.parse(data.jsonValue);
        console.log('jsonValuejsonValuejsonValuejsonValuejsonValue')
        console.log(this.params)
        if(this.params.baseInfoCustomField){
          this.$parent.BaseInfoCustomFieldAttr = JSON.parse(this.params.baseInfoCustomField)
          this.baseInfoCustomFieldList = JSON.parse(this.params.baseInfoCustomField)
        }
        this.$parent.params.productId=this.params.id;
        this.$parent.params.productType=this.params.productType;
        this.$parent.otherType=this.params.productType;
        this.$parent.params.BaseInfoProductName=this.params.productName;
        this.$parent.params.ProductSubType=this.params.ProductSubType;
        this.$parent.params.BaseInfoItemCode=this.params.itemCode;
        this.$parent.params.BaseInfoProductDescription=this.params.productDescription;
        this.$parent.params.versionNumber=this.params.versionNumber;
        this.$parent.params.confirmed=data.confirmed;
        this.params.productId = data.id;
        this.params.verifiedList = data.verifiedList;
        this.strSplit(this.params.inciName, "inclNameArr");
        
        this.strSplit(this.params.botanicalName, "botanicalNameArr");
        this.strSplit(this.params.commonOtherName, "commonOtherNameArr");
        this.strSplit(this.params.partUsed, "partUsedArr");
        this.strSplit(this.params.percentage, "percentageArr");
        this.strSplit(this.params.processing, "processingArr");
        this.strSplit(this.params.majorConstituentsInNativeHerbs,"majorConstituentsInNativeHerbsArr");
        this.strSplit(this.params.preservative, "preservativeArr");
        this.strSplit(this.params.commonName, "commonNameArr");

        if(this.params.newNoteArr == undefined){
          this.params.newNoteArr = []
          this.newNoteArr = []
        }
        else{
          this.newNoteArr = JSON.parse(this.params.newNoteArr);
        }
        localStorage.setItem('local_noteTableData',this.params.noteTableData);
        this.noteTableData = JSON.parse(this.params.noteTableData);
        if(!this.noteTableData){
          this.noteTableData = [{"revisionDate":"","requestedBy":"","itemsRevised":"","editedBy":"","reason":"","approvedBy":"","isComplete":false}];
        }
        console.log('this.noteTableData')
        this.noteTableData.forEach(item=>{
          if(item.reason == undefined){
            item.reason = ''
          }
          if(item.isComplete == undefined){
            item.isComplete = false
          }
          console.log(item.reason)
          console.log(item.isComplete)
        })
        this.noteTableData = JSON.parse(JSON.stringify(this.noteTableData))
        let revNumber = 0
        this.noteTableData.forEach(item=>{
          if(item.isComplete){
            revNumber ++
          }
        })
        if(this.params.revNumber == undefined){
          this.params.revNumber = 0
        }
        if(this.params.revDate == undefined){
          this.params.revDate = []
        }
        if(this.params.revDate.length>0){
          let revDate = this.params.revDate[this.params.revDate.length-1]
          if(revDate){
            this.$parent.revDateTime = revDate
          }
        }
        if(revNumber==0){
          this.$parent.revDateTime = ""
        }
        else{
          if(!this.$parent.revDateTime){
            this.$parent.revDateTime = data.updateTime
          }
        }
        

        this.params.revNumber = revNumber
        this.$parent.params.revNumber=this.params.revNumber;
        this.$parent.params.revDate=this.params.revDate;
        console.log(this.noteTableData)
        //TODO:shTestArr push
        if (!data.shTest || data.shTest === null) {
          this.shTestArr.push({
            testLimitAcidValue: data.testLimitAcidValue,
            testLimitPeroxideValue: data.testLimitPeroxideValue,
            testLimitSaponificationValue: data.testLimitSaponificationValue,
            testLimitUnsaponifiableMaster: data.testLimitUnsaponifiableMaster,
            testLimitMoistureVolatiles: data.testLimitMoistureVolatiles,
            testLimitLead: data.testLimitLead,
            testLimitArsenic: data.testLimitArsenic,
            testLimitCadmium: data.testLimitCadmium,
            testLimitMercury: data.testLimitMercury,
            testLimitPesticides: data.testLimitPesticides,
            testMethodAcidValue: data.testMethodAcidValue,
            testMethodPeroxideValue: data.testMethodPeroxideValue,
            testMethodSaponificationValue: data.testMethodSaponificationValue,
            testMethodUnsaponifiableMaster: data.testMethodUnsaponifiableMaster,
            testMethodMoistureVolatiles: data.testMethodMoistureVolatiles,
            testMethodLead: data.testMethodLead,
            testMethodArsenic: data.testMethodArsenic,
            testMethodCadmium: data.testMethodCadmium,
            testMethodMercury: data.testMethodMercury,
            testMethodPesticides: data.testMethodPesticides
          });
        } else {
          this.shTestArr = JSON.parse(data.shTest)
        }

        if (!data.fattyAcidComposition || data.fattyAcidComposition === null) {
          this.fattyAcidCompositionArr.push({
            fattryLimitPalmitica: data.fattryLimitPalmitica,
            fattryLimitPalmitoleic: data.fattryLimitPalmitoleic,
            fattryLimitStearic: data.fattryLimitStearic,
            fattryLimitOleicVaccenic: data.fattryLimitOleicVaccenic,
            fattryLimitLinoleic: data.fattryLimitLinoleic,
            fattryLimitALinolenic: data.fattryLimitALinolenic,
            fatterLimitOthers: data.fatterLimitOthers,
            fattryMethodPalmitica: data.fattryMethodPalmitica,
            fattryMethodPalmitoleic: data.fattryMethodPalmitoleic,
            fattryMethodStearic: data.fattryMethodStearic,
            fattryMethodOleicVaccenic: data.fattryMethodOleicVaccenic,
            fattryMethodLinoleic: data.fattryMethodLinoleic,
            fattryMethodALinolenic: data.fattryMethodALinolenic,
            fatterMethodOthers: data.fatterMethodOthers,
          });
        } else {
          this.fattyAcidCompositionArr = JSON.parse(data.fattyAcidComposition)
        }
        
        if (this.params.genSpecCustomField) {
          this.genSpecCustomFieldAttr=JSON.parse(this.params.genSpecCustomField)
        }
        if (this.params.analysisCustomField) {
          this.analysisCustomFieldAttr=JSON.parse(this.params.analysisCustomField)
        }
        if (this.params.micTestsCustomField) {
          this.micTestsCustomFieldAttr=JSON.parse(this.params.micTestsCustomField)
        }
        if (this.params.tableInputCustomField) {
          this.tableInputCustomFieldAttr=JSON.parse(this.params.tableInputCustomField)
        }
        //非空默认值
        this.setEmptyDefaultValue();
        this.changeShelflife(this.params.productType,this.params.shelflife);
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  getCnSpecLData(params){
    this.$api.handleCnSpec
      .getDetail(params)
      .then((data) => {
        this.hasVerifyItemCode = true;
        this.loading = false; 
        console.info("getCnSpecLData");
        console.info(data);
        console.info("data.jsonValue");
        this.form = JSON.parse(data.jsonValue);
        console.log(this.form)
        if(this.form.baseInfoCustomField){
          this.$parent.BaseInfoCustomFieldAttr = JSON.parse(this.form.baseInfoCustomField)
          this.baseInfoCustomFieldList = JSON.parse(this.form.baseInfoCustomField)
        }
        this.$parent.params.productId=this.form.productId;
        this.$parent.params.productType=this.form.productType;
        this.$parent.otherType=this.form.productType;
        this.$parent.params.BaseInfoProductName=this.form.BaseInfoProductName;
        this.$parent.params.ProductSubType=this.form.ProductSubType;
        this.$parent.params.BaseInfoItemCode=this.form.BaseInfoItemCode;
        this.$parent.params.BaseInfoProductDescription=this.form.BaseInfoProductDescription;
        this.$parent.params.versionNumber=this.form.versionNumber;
        this.$parent.params.confirmed=data.confirmed;
        this.form.productId = data.id;
        this.form.verifiedList = data.verifiedList;

        //newNote添加
        this.newNote = this.form.newNote;
        // console.info('aaaa:',this.params.inciName)
        //this.params.specificationEcoli="";
        // this.strSplit(this.params.ingredients, "ingredientsArr");
        this.strSplit(this.form.inciName, "inclNameArr");
        this.strSplit(this.form.botanicalName, "botanicalNameArr");
        this.strSplit(this.form.commonName, "commonNameArr");
        this.strSplit(this.form.nativeHerbs, "nativeHerbsArr");
        this.strSplit(this.form.partUsed, "partUsedArr");
        this.strSplit(this.form.percentage, "percentageArr");

        if (this.form.genSpecCustomField) {
          this.genSpecCustomFieldAttr=JSON.parse(this.form.genSpecCustomField)
        }
        if (this.form.analysisCustomField) {
          this.analysisCustomFieldAttr=JSON.parse(this.form.analysisCustomField)
        }
        if (this.form.tableInputCustomField) {
          this.tableInputCustomFieldAttr=JSON.parse(this.form.tableInputCustomField)
        }

        if(this.form.newNoteArr == undefined){
          this.form.newNoteArr = []
          this.newNoteArr = []
        }
        else{
          this.newNoteArr = JSON.parse(this.form.newNoteArr);
        }
        localStorage.setItem('local_noteTableData',this.form.noteTableData);
        this.noteTableData = JSON.parse(this.form.noteTableData);
        if(!this.noteTableData){
          this.noteTableData = [{"revisionDate":"","requestedBy":"","itemsRevised":"","editedBy":"","reason":"","approvedBy":"","isComplete":false}];
        }
        console.log('this.noteTableData')
        this.noteTableData.forEach(item=>{
          if(item.reason == undefined){
            item.reason = ''
          }
          if(item.isComplete == undefined){
            item.isComplete = false
          }
          console.log(item.reason)
          console.log(item.isComplete)
        })
        this.noteTableData = JSON.parse(JSON.stringify(this.noteTableData))
        let revNumber = 0
        this.noteTableData.forEach(item=>{
          if(item.isComplete){
            revNumber ++
          }
        })
        if(this.form.revNumber == undefined){
          this.form.revNumber = 0
        }
        if(this.form.revDate == undefined){
          this.form.revDate = []
        }
        if(this.form.revDate.length>0){
          let revDate = this.form.revDate[this.form.revDate.length-1]
          if(revDate){
            this.$parent.revDateTime = revDate
          }
        }
        if(revNumber==0){
          this.$parent.revDateTime = ""
        }
        else {
          if(!this.$parent.revDateTime){
            this.$parent.revDateTime = data.updateTime
          }
        }
        this.form.revNumber = revNumber
        this.$parent.params.revNumber=this.form.revNumber;
        this.$parent.params.revDate=this.form.revDate;
        console.log(this.noteTableData)
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  getCnSpecEData(params){
    this.$api.handleCnSpec
      .getDetail(params)
      .then((data) => {
        this.hasVerifyItemCode = true;
        this.loading = false; 
        console.info("getCnSpecEData");
        console.info(data);
        this.form = JSON.parse(data.jsonValue);
        console.log(this.params)
        if(this.form.baseInfoCustomField){
          this.$parent.BaseInfoCustomFieldAttr = JSON.parse(this.form.baseInfoCustomField)
          this.baseInfoCustomFieldList = JSON.parse(this.form.baseInfoCustomField)
        }
        this.form.productId = data.id;
        this.form.verifiedList = data.verifiedList;
        this.$parent.params.productId=this.form.productId;
        this.$parent.params.productType=this.form.productType;
        this.$parent.otherType=this.form.productType;
        this.$parent.params.BaseInfoProductName=this.form.productName;
        this.$parent.params.ProductSubType=this.form.ProductSubType;
        this.$parent.params.BaseInfoItemCode=this.form.itemCode;
        this.$parent.params.BaseInfoProductDescription=this.form.productDescription;
        this.$parent.params.versionNumber=this.form.versionNumber;
        this.$parent.params.confirmed=data.confirmed;
        
        // console.info('aaaa:',this.params.inciName)
        //this.params.specificationEcoli="";
        // this.strSplit(this.params.ingredients, "ingredientsArr");
        this.strSplit(this.form.inciName, "inclNameArr");
        this.strSplit(this.form.botanicalName, "botanicalNameArr");
        this.strSplit(this.form.commonName, "commonNameArr");
        this.strSplit(this.form.nativeHerbs, "nativeHerbsArr");
        this.strSplit(this.form.partUsed, "partUsedArr");
        this.strSplit(this.form.percentage, "percentageArr");

        if (this.form.genSpecCustomField) {
          this.genSpecCustomFieldAttr=JSON.parse(this.form.genSpecCustomField)
        }
        if (this.form.analysisCustomField) {
          this.analysisCustomFieldAttr=JSON.parse(this.form.analysisCustomField)
        }
        if (this.form.micTestsCustomField) {
          this.micTestsCustomFieldAttr=JSON.parse(this.form.micTestsCustomField)
        }
        if (this.form.tableInputCustomField) {
          this.tableInputCustomFieldAttr=JSON.parse(this.form.tableInputCustomField)
        }
        if(this.form.newNoteArr == undefined){
          this.form.newNoteArr = []
          this.newNoteArr = []
        }
        else{
          this.newNoteArr = JSON.parse(this.form.newNoteArr);
        }

        localStorage.setItem('local_noteTableData',this.form.noteTableData);
        this.noteTableData = JSON.parse(this.form.noteTableData);
        if(!this.noteTableData){
          this.noteTableData = [{"revisionDate":"","requestedBy":"","itemsRevised":"","editedBy":"","reason":"","approvedBy":"","isComplete":false}];
        }
        console.log('this.noteTableData')
        this.noteTableData.forEach(item=>{
          if(item.reason == undefined){
            item.reason = ''
          }
          if(item.isComplete == undefined){
            item.isComplete = false
          }
          console.log(item.reason)
          console.log(item.isComplete)
        })
        this.noteTableData = JSON.parse(JSON.stringify(this.noteTableData))
        let revNumber = 0
        this.noteTableData.forEach(item=>{
          if(item.isComplete){
            revNumber ++
          }
        })
        if(this.form.revNumber == undefined){
          this.form.revNumber = 0
        }
        if(this.form.revDate == undefined){
          this.form.revDate = []
        }
        if(this.form.revDate.length>0){
          let revDate = this.form.revDate[this.form.revDate.length-1]
          if(revDate){
            this.$parent.revDateTime = this.form.revDate[revDate]
          }
        }
        if(revNumber==0){
          this.$parent.revDateTime = ""
        }
        else{
          if(!this.$parent.revDateTime){
            this.$parent.revDateTime = data.updateTime
          }
        }
        this.form.revNumber = revNumber
        this.$parent.params.revNumber=this.form.revNumber;
        this.$parent.params.revDate=this.form.revDate;

        
        console.log(this.noteTableData)
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  getCnSpecSData(params){
    this.$api.handleCnSpec
      .getDetail(params)
      .then((data) => {
        // this.hasVerifyItemCode = true;
        // this.loading = false; 
        console.info("getCnSpecSData");
        console.info(data);
        this.form = JSON.parse(data.jsonValue);
        console.log(this.form)
        if(this.form.baseInfoCustomField){
          this.$parent.BaseInfoCustomFieldAttr = JSON.parse(this.form.baseInfoCustomField)
          this.baseInfoCustomFieldList = JSON.parse(this.form.baseInfoCustomField)
        }
        this.form.verifiedList = data.verifiedList;
        this.form.productId = data.id;
        this.$parent.params.productId=this.form.productId;
        this.$parent.params.productType=this.form.productType;
        this.$parent.otherType=this.form.productType;
        this.$parent.params.BaseInfoProductName=this.form.BaseInfoProductName;
        this.$parent.params.ProductSubType=this.form.ProductSubType;
        this.$parent.params.BaseInfoItemCode=this.form.BaseInfoItemCode;
        this.$parent.params.BaseInfoProductDescription=this.form.BaseInfoProductDescription;
        this.$parent.params.versionNumber=this.form.versionNumber;
        this.$parent.params.confirmed=data.confirmed;

        this.strSplit(this.form.inciName, "inclNameArr");
        this.strSplit(this.form.botanicalName, "botanicalNameArr");
        this.strSplit(this.form.commonName, "commonNameArr");
        this.strSplit(this.form.partUsed, "partUsedArr");

        this.newNote = this.form.newNote;
        if (this.form.countryOfOrigin) {
          this.countryOfOrigin=this.form.countryOfOrigin;
        }

        if (this.form.genSpecCustomField) {
          this.genSpecCustomFieldAttr=this.form.genSpecCustomField
        }
        if (this.form.analysisCustomField) {
          this.analysisCustomFieldAttr=this.form.analysisCustomField
        }
        console.info("asdf");
        if (this.form.fattyCustomField) {
          this.fattyCustomFieldAttr=this.form.fattyCustomField
        }
        if (this.form.micTestsCustomField) {
          this.micTestsCustomFieldAttr=this.form.micTestsCustomField
        }
        console.info("asd33");
        if (this.form.tableInputCustomField) {
          this.tableInputCustomFieldAttr=this.form.tableInputCustomField
        }
        if(this.form.newNoteArr == undefined){
          this.form.newNoteArr = []
          this.newNoteArr = []
        }
        else{
          this.newNoteArr = JSON.parse(this.form.newNoteArr);
        }

        localStorage.setItem('local_noteTableData',this.form.noteTableData);
        this.noteTableData = JSON.parse(this.form.noteTableData);
        if(!this.noteTableData){
          this.noteTableData = [{"revisionDate":"","requestedBy":"","itemsRevised":"","editedBy":"","reason":"","approvedBy":"","isComplete":false}];
        }
        console.log('this.noteTableData')
        this.noteTableData.forEach(item=>{
          if(item.reason == undefined){
            item.reason = ''
          }
          if(item.isComplete == undefined){
            item.isComplete = false
          }
          console.log(item.reason)
          console.log(item.isComplete)
        })
        this.noteTableData = JSON.parse(JSON.stringify(this.noteTableData))
        let revNumber = 0
        this.noteTableData.forEach(item=>{
          if(item.isComplete){
            revNumber ++
          }
        })
        if(this.form.revNumber == undefined){
          this.form.revNumber = 0
        }
        if(this.form.revDate == undefined){
          this.form.revDate = []
        }
        if(this.form.revDate.length>0){
          let revDate = this.form.revDate[this.form.revDate.length-1]
          if(revDate){
            this.$parent.revDateTime = this.form.revDate[revDate]
          }
        }
        if(revNumber==0){
          this.$parent.revDateTime = ""
        }
        else{
          if(!this.$parent.revDateTime){
            this.$parent.revDateTime = data.updateTime
          }
        }
        this.form.revNumber = revNumber
        this.$parent.params.revNumber=this.form.revNumber;
        this.$parent.params.revDate=this.form.revDate;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
  //非空默认值
  setEmptyDefaultValue(){
    if(!this.params.pesticides){
      this.params.pesticides= "Conforms to USP<565>";
    }
    // console.info('aaa:',this.params.specificationSalmonella)
    // if(this.params.specificationEcoli=='Negative'){
    //   this.params.specificationEcoli="";
    // }
    // if(this.params.specificationSalmonella=='Negative'){
    //   this.params.specificationSalmonella="";
    // }
    // if(this.params.specificationStaphAureus=='Negative'){
    //   this.params.specificationStaphAureus="";
    // }

    if(!this.params.testMethodArsenic){
      this.params.testMethodArsenic = "ICP-MS";
    }
    if(!this.params.testMethodCadmium){
      this.params.testMethodCadmium = "ICP-MS";
    }
    if(!this.params.testMethodLead){
      this.params.testMethodLead = "ICP-MS";
    }
    if(!this.params.testMethodMercury){
      this.params.testMethodMercury = "ICP-MS";
    }
  },
  check() {
    if (this.params.itemCode.trim() == "") {
      return;
    }
    let data = { itemCode: this.params.itemCode, id: this.params.id };
    console.log("check()check()check()data")
    console.log(data)
    this.$api.handleSpec
      .checkItemCode(data)
      .then((data) => {
        this.hasVerifyItemCode = true;
        if (data) {
          this.$message({
            type: "error",
            message: "Item Code Already Exists",
          });
          this.itemCodeExist = 0;
          this.checkItemCode = true;
        } else {
          this.$message({ type: "success", message: "Item Code Is Usable" });
          this.itemCodeExist = 2;
          this.checkItemCode = false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  vertify() {
    let arrtr = this.noteTableData[this.noteTableData.length-1]
    if(arrtr.revisionDate =='' || arrtr.requestedBy =='' || arrtr.itemsRevised =='' || arrtr.editedBy =='' || arrtr.reason ==''){
      this.$message({ type: "info", message: "Revision note Cannot be empty" });
      return
    }
    console.log(arrtr)
    this.$confirm("Vertify context no question?", "提示", {
      confirmButtonText: "Verify Ok",
      cancelButtonText: "Verify Cancel",
      type: "warning",
      distinguishCancelAndClose: true,
    })
      .then(() => {
        // this.$set(this.params,'verified','true');
        this.verifyChange(true);
      })
      .catch((action) => {
        // this.$set(this.params, "verified", "false");
        if (action == "cancel") {
          this.verifyChange(false);
        }
      });
  },
  verifyChange(flag) {
    if (this.isVerifing) return;
    this.isVerifing = true;
    let params = {
      id: this.$route.query.id,
      pageIndex: 1,
      pageSorts: [
        {
          column: "",
          asc: false,
        },
      ],
      isFlag: flag,
      pageSize: 100,
      itemCode: "",
      productName: "",
    };
    this.$api
      .verfiedItem(params)
      .then((data) => {
        console.log(data);
        this.$set(this.params, "verified", flag.toString());
        this.$message({ type: "success", message: "Verified SuccessFul" });
        this.isVerifing = false;
        console.log(this.specUserInfo.username)
        
        this.noteTableData.forEach((item,i)=>{
          if(i == this.noteTableData.length-1){
            item.approvedBy = this.specUserInfo.username
            item.isComplete = true
          }
        })
        if(flag){
          this.addToLibrary()
        }else{
          var note = this.noteTableData[this.noteTableData.length-1];
          note.isComplete = false;

        }
        
      })
      .catch(() => {
        // console.log(err);
        // this.$message({ type: "error", message: 'Verify Fail' });
        // this.$message({type:'error',message:err.data});
        // this.$set(this.params,'verified','false');
        this.isVerifing = false;
      });
  },
  addToLibrary(){
    this.$confirm(
      "Are you sure add to Library. Continue?",
      "Tips",
      {
        confirmButtonText: "Ok",
        cancelButtonText: "Cancel",
        distinguishCancelAndClose: true,
        type: "warning",
      }
    ).then(() => {
      let param = { ...this.$route.query };
      let params ={ids:[],type:'spec'}
      let ids=[]
      ids.push(param.id)
      params.ids = ids
      
      console.log('addToLibrary======params')
      console.log(params)
      this.$api
        .batchAddLibrary(params)
        .then(() => {
          this.$message({ type: "success", message: "Add SuccessFully" });
          this.params.libraryRevNumber=parseInt(this.params.revNumber)+1
          this.params.libraryCreateTime=this.formatTime(base.methods.getLocalNowTime(),"dd/MM/yyyy hh:mm")
          this.noteTableData.push({
            revisionDate:"",
            requestedBy:"",
            itemsRevised:"",
            editedBy:"",
            approvedBy:"",
            reason:"",
            isComplete:false,
          });
          this.handleNew('Continue Edit',2)
        })
        .catch((err) => {
          console.log(err);
        });
    });
  },
  strSplit(context, type) {
  this[type] = [];//清理旧有数据防止重复
    if (!context) {
      return;
    }
    let splitArr = context.split("_");
    if(splitArr.length==1){
      splitArr=  context.split(",");
    }
    splitArr.forEach((v) => {
      if(v.trim().length!=0){
        this[type].push(v);
      }
  });
  
  },
  disposeStr(str, type) {
    str.split("_").forEach((v) => {
        if(v.trim().length!=0){
          this[type].push(v);
        }
      
    });
  },
  ingredientsTags(index){
    var arr = [];
    let eachArr = this.ingredientsArr;
    eachArr.forEach(element => {
        var str = element.replace('，',',');
        var tmps = str.split(',');
        if(tmps.length>index){
            arr.push(tmps[index]);
        }
    });
    if(arr==[]){
      return this.printModel.ingredientsArr
    }
    // console.info(index,arr );
    return arr;
  },
  addNoteTableClick(nowIndex,type){
    this.noteTableData.forEach((item,index)=>{
      if(index == nowIndex){
        item.isComplete = true
        if(type == 'en'){
          this.params.revNumber++
        }
        if(type == 'cn'){
          this.$parent.params.revNumber++
        }
      }
    })
    let revNumber = 0
    this.noteTableData.forEach((item)=>{
      if(item.isComplete == true){
        revNumber++
      }
    })
    if(type == 'en'){
      this.params.revNumber=revNumber
    }
    if(type == 'cn'){
      this.$parent.params.revNumber=revNumber+1
    }
    this.noteTableData.push({
      revisionDate:"",
      requestedBy:"",
      itemsRevised:"",
      editedBy:"",
      approvedBy:"",
      reason:"",
      isComplete:false,
    });
  },
  removeNoteTableClick(idx){
    this.noteTableData.splice(idx,1);
  },
  showData:function(type){
    console.log("typeGG:"+type);
    if(type=="CSFMU-S"||type=="CSFMU-B"||type=="F&V"||type=="FMU-D"||type=="HEXT"||type=="PCG"||type=="REXT"||type=="OEXT"||type=="PCG-CSFMU-S"||type=="PCG-CSFMU-B"||type=="SDNP")
    {
      this.colorList=this.colorPowder; 
      //所有的 Powder
      if(this.params.specificationTotalColiforms==""){
        this.params.specificationTotalColiforms="10"; 
      }
      this.params.productDescription="Spray dried, fine powdered botanical extract of "+this.botanicalNameArr.toString()+" that has been extracted in a medium temperature, full spectrum extraction process. "
      
    }else if(type=="Liquid EXT-S"||type=="Liquid EXT-B"||type=="SDNP-L")
    {
      this.colorList=this.colorLiquid;   
      if(this.params.analysisRangeYeastMold==""){ 
        this.params.analysisRangeYeastMold=100;
      } 
    }else if(type=="EHXTO"||type=="SHXTO")
    {
      this.colorList=this.colorOil;
      if(!this.params.testMethodTotalColiforms){
        this.params.testMethodTotalColiforms="FDA-BAM";
      }
    }
    
    //this.params.productDescription="";
    //this.params.pesticides="";
    if(type=="SDNP"){
      this.params.productDescription="Spray dried, fine powdered botanical extract of ingredients which has been extracted in a lower-temperature process.";
      this.params.pesticides="Conforms to USP<565>";
    }
    if(!this.params.pesticides){
      this.params.pesticides= "Conforms to USP<565>";
    }

    if(type=="CSFMU-B"||type=="FMU-D"||type=="PCG-CSFMU-B"){
      this.params.physicalProperties= "Blend";
    }else if(type=="CSFMU-S"||type=="F&V"||type=="HEXT"||type=="REXT"||type=="OEXT"||type=="PCG"||type=="PCG-CSFMU-S"){
      this.params.physicalProperties= "Single";
    }else if(type=="Liquid EXT-S"||type=="Liquid EXT-B"){
      this.params.physicalProperties= "Liquid";
    }else if(type=="SHXTO"){
      this.params.physicalProperties= "Oil";
    }else if(type=="EHXTO"){
      this.params.physicalProperties= "Essential oil";
    }else if(type=="SDNP"){
      this.params.physicalProperties= "Liquid";
      if(this.params.appearance=="Granular"){
        this.params.physicalProperties= "Granulate";
      }
      else if(this.params.appearance=="Powder"){
        this.physicalPropertiesList.push({ label: "Power", value: "Power" });
      }
    }
    // CSFMU-S, F&V, HEXT, REXT, OEXT, PCG, PCG-CSFMU-S
    // this.params.majorConstituentsInNativeHerbs="gg_aa_bb_cc_dd，1，2，333";
    // this.params.majorConstituentsInNativeHerbs=this.params.majorConstituentsInNativeHerbs.replace(/_/g,",");
    // this.params.majorConstituentsInNativeHerbs=this.params.majorConstituentsInNativeHerbs.replace(/，/g,",");
    // console.log("gg");
  },
  showDataInfo:function(type){
    console.log("typeGG:"+type);
    if(type=="CSFMU-S"||type=="CSFMU-B"||type=="F&V"||type=="FMU-D"||type=="HEXT"||type=="PCG"||type=="REXT"||type=="OEXT"||type=="PCG-CSFMU-S"||type=="PCG-CSFMU-B"||type=="SDNP")
    {
      this.colorList=this.colorPowder; 
    }
    else if(type=="Liquid EXT-S"||type=="Liquid EXT-B"||type=="SDNP-L")
    {
      this.colorList=this.colorLiquid; 
    }
    else if(type=="EHXTO"||type=="SHXTO")
    {
      this.colorList=this.colorOil;
    }
  },
  addGenSpecCustomRow(type,index){
    if(type==1){
      this.genSpecCustomFieldAttr.push({ ...this.customField });
    }
    else{
      this.$delete(this.genSpecCustomFieldAttr,index)
    }
  },
  addAnalysisCustomRow(type,index){
    if(type==1){
      this.analysisCustomFieldAttr.push({ ...this.customField });
    }
    else{
      this.$delete(this.analysisCustomFieldAttr,index)
    }
  },
  addMicTestsCustomRow(type,index){
    if(type==1){
      this.micTestsCustomFieldAttr.push({ ...this.customField });
    }
    else{
      this.$delete(this.micTestsCustomFieldAttr,index)
    }
  },
  addTestCustomRow(type,index,pIndex){
    if(type==1){
      this.shTestArr[pIndex].testCustomFieldAttr.push({ ...this.customField });
    }
    else{
      this.$delete(this.shTestArr[pIndex].testCustomFieldAttr,index)
    }
  },
  addFattyCustomRow(type,index,pIndex){
    if(type==1){
      this.fattyAcidCompositionArr[pIndex].fattyCustomFieldAttr.push({ ...this.customField });
    }
    else{
      this.$delete(this.fattyAcidCompositionArr[pIndex].fattyCustomFieldAttr,index)
    }
  },
  addShxtoFattyCustomRow(type,index){
    if(type==1){
      this.fattyCustomFieldAttr.push({ ...this.customField });
    }
    else{
      this.$delete(this.fattyCustomFieldAttr,index)
    }
  },
  addTableInput(type,index){
    if(type==1){
      this.tableInputCustomFieldAttr.push({ ...this.tableInputField });
    }
    else{
      this.$delete(this.tableInputCustomFieldAttr,index)
    }
  },
  checkSpecificationTotalColiforms(){
    this.params.specificationTotalColiforms=this.params.specificationTotalColiforms.toLowerCase()=='negative'?this.params.specificationTotalColiforms:this.params.specificationTotalColiforms.replace(/[^\d.]|/g,'')
  },
  checktestMethodTotalColiforms(){
    this.params.testMethodTotalColiforms=this.params.testMethodTotalColiforms.toLowerCase()=='negative'?this.params.testMethodTotalColiforms:this.params.testMethodTotalColiforms.replace(/[^\d.]|/g,'')
  },
  checkSpecificationTotalColiformscn(){
    this.form.specificationTotalColiforms = 
      (this.form.specificationTotalColiforms.toLowerCase()=='negative' || this.form.specificationTotalColiforms=='阴性')?
      this.form.specificationTotalColiforms:
      this.form.specificationTotalColiforms.replace(/[^\d.]|/g,'')
  },
  checkSpecificationTotalColiformscnpower(){
    this.params.specificationTotalColiforms = 
      (this.params.specificationTotalColiforms.toLowerCase()=='negative' || this.params.specificationTotalColiforms=='阴性')?
      this.params.specificationTotalColiforms:
      this.params.specificationTotalColiforms.replace(/[^\d.]|/g,'')
  },
  checktestMethodTotalColiformscn(){
    this.form.testMethodTotalColiforms = 
      (this.form.testMethodTotalColiforms.toLowerCase()=='negative' || this.form.testMethodTotalColiforms=='阴性')?
      this.form.testMethodTotalColiforms:
      this.form.testMethodTotalColiforms.replace(/[^\d.]|/g,'')
  },
  formatDate(time) {
    return base.methods.formatDate({ date: time });
  },
  formatTime(time,format){
    if(time){
      return base.methods.formatTime(time,format);
    }
    else{
      return base.methods.formatTime(new Date(),format);
    }
  },
  addNewNoteTableClick(){
    this.newNoteArr.push({
      content:this.newNote
    })
    this.newNote = ''
  },
  deleteNewNoteTableClick(index){
    this.newNoteArr.splice(index,1);
    // this.$delete(this.newNoteArr,index)
  },
  cnpemission(username){
    console.log(username)
    return base.methods.pemission(username)
  },
  cnvertify(productId,proType) {
    console.log(this.noteTableData)
    let arrtr = this.noteTableData[this.noteTableData.length-1]
    if(arrtr.revisionDate =='' || arrtr.requestedBy =='' || arrtr.itemsRevised =='' || arrtr.editedBy =='' || arrtr.reason ==''){
      this.$message({ type: "info", message: "Revision note 不能为空" });
      return
    }
    this.$confirm("确认要审核吗?", "提示", {
      confirmButtonText: "审核",
      cancelButtonText: "取消",
      type: "warning",
      distinguishCancelAndClose: true,
    })
      .then(() => {
        this.$api.handleCnSpec.cnverfiedUrl({id:productId,note:""})
        .then((data) => {
          console.log('cnverfiedUrl');
          console.log(data);
          this.addRevNumber(proType)
          this.$message({ 
            type: "success", 
            message: "审核成功"
          });
        })
        .catch(() => {
        });
      })
  },
  addRevNumber(proType){
    let noteCount = this.noteTableData.length
    this.noteTableData.forEach((item,i)=>{
      if(i == noteCount-1){
        item.approvedBy = this.$parent.specUserInfo.username
        item.isComplete = true
      }
    })
    this.noteTableData.push({
      revisionDate:"",
      requestedBy:"",
      itemsRevised:"",
      editedBy:"",
      approvedBy:"",
      reason:"",
      isComplete:false,
    });
    console.log('this.noteTableData')
    console.log(this.noteTableData)
    this.$parent.revDateTime = base.methods.getLocalNowTime()
    if(proType == 'liquid'){
      this.form.revDate.push(this.$parent.revDateTime)
      this.handleCnSpecLNew('Continue Edit',2)
    }
    else if(proType == 'powder'){
      this.params.revDate.push(this.$parent.revDateTime)
      this.handleCnSpecNew('Continue Edit',2)
    }
    else if(proType == 'ehxto'){
      this.form.revDate.push(this.$parent.revDateTime)
      this.handleCnSpecENew('Continue Edit',2)
    }
    else if(proType == 'shxto'){
      this.form.revDate.push(this.$parent.revDateTime)
      this.handleCnSpecSNew('Continue Edit',2)
    }

  },
  AddToVerifyList(){},
  addBaseInfoInput(type,index){
    if(type==1){
      this.BaseInfoCustomFieldAttr.push({ ...this.baseInfoField });
    }
    else{
      this.$delete(this.BaseInfoCustomFieldAttr,index)
    }
  },
}